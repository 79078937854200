<template>
  <div v-if="total > perPage" class="pagination-container">
    <BPagination
      v-model="page"
      :total-rows="totalCapped"
      :per-page="perPage"
      :prev-text="$texts('back', 'Zurück')"
      :next-text="$texts('next', 'Weiter')"
      @page-click="handleInput"
    />
  </div>
</template>

<script setup lang="ts">
// Props
const props = defineProps({
  modelValue: {
    type: Number,
    default: 1,
  },
  perPage: {
    type: Number,
    default: 20,
  },
  total: {
    type: Number,
    default: 0,
  },
})

const totalCapped = computed(() => {
  if (props.total > 1000) {
    return 1000
  }
  return props.total
})

const { $texts } = useNuxtApp()

const emit = defineEmits(['update:modelValue'])
const page = ref(1)
const handleInput = (e: any, pageNumber: string) => {
  page.value = parseInt(pageNumber)
  emit('update:modelValue', page.value as number)
}
watch(
  () => props.modelValue,
  (nVal) => (page.value = nVal as number),
  {
    immediate: true,
  },
)
</script>

<style lang="scss">
@import '~/assets/scss/variables';
.pagination-container {
  padding: $size-3 0;
  border-top: 1px solid $border-color;
  margin-top: $size-3;
  @include media-breakpoint-up(lg) {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 100;
  }
}

.pagination {
  font-size: 1rem;
  li {
    &:first-child {
      margin-right: auto;
    }
    &:last-child {
      margin-left: auto;
    }
  }
}
</style>
